<template>
  <div class="page">
    <div v-if="getModuleConfig('welcome_page.step_section.enable_step_section')" class="p-3">
      <SharedStep :steps="getModuleConfig('welcome_page.step_section.steps')" />
    </div>

    <div v-if="getModuleConfig('welcome_page.form_title')" class="page__title">
      {{ getModuleConfig("welcome_page.form_title") }}
    </div>
    <div v-if="getModuleConfig('welcome_page.form_desc')" class="page__desc">
      {{ getModuleConfig("welcome_page.form_desc") }}
    </div>

    <div v-if="contentData" class="page__content" v-html="contentData"></div>

    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
      <MemberButton
        v-if="showSuccessButton"
        :button-text="getModuleConfig('welcome_page.button_text')"
        :button-url="getModuleConfig('welcome_page.button_url')"
        :type="getModuleConfig('welcome_page.button_action')"
      ></MemberButton>
      <SharedButton class="s-btn-outline-primary bg-transparent" @click="redirectToIntended" v-if="showRedirectButton">
        {{ redirectActionConfig.button_text }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import registerMixin from "@/mixins/liff/register";
import SharedStep from "@/components/Page/Liff/Shared/Steps";

const BUTTON_TYPE = {
  SUCCESS: "success_button",
  REDIRECT: "redirect_button",
};

export default {
  mixins: [registerMixin],
  components: {
    SharedButton,
    SharedStep,
    MemberButton,
  },
  computed: {
    ...mapGetters({
      hasIntended: "route/hasIntended",
    }),
    contentImages() {
      let contentImages = this.getModuleConfig("welcome_page.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    redirectActionConfig() {
      return this.getModuleConfig("welcome_page.redirect_action") || {
        enable: false,
        button_type: [],
      };
    },
    redirectActionEnabled() {
      return this.hasIntended && this.redirectActionConfig.enable;
    },
    showSuccessButton() {
      return !this.redirectActionEnabled || this.redirectActionConfig.button_type.includes(BUTTON_TYPE.SUCCESS);
    },
    showRedirectButton() {
      return this.redirectActionEnabled && this.redirectActionConfig.button_type.includes(BUTTON_TYPE.REDIRECT);
    },
    skipWelcomePage() {
      const allowedButtonTypes = Object.values(BUTTON_TYPE);

      return this.redirectActionEnabled && this.redirectActionConfig.button_type.filter((type) => allowedButtonTypes.includes(type)).length === 0;
    },
    showPage() {
      return this.getModuleConfig('welcome_page.step_section.enable_step_section')
        || this.getModuleConfig('welcome_page.form_title')
        || this.getModuleConfig('welcome_page.form_desc')
        || this.content
    },
    contentData() {
      return this.getModuleConfig("welcome_page.content") ?? false;
    },
  },
  async created() {
    if (this.skipWelcomePage) {
      await this.redirectToIntended();
    }
  },
  methods: {
    ...mapActions({
      intended: "route/intended",
    }),
    redirectToIntended() {
      this.intended();
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}
.item {
  display: flex;
  margin-bottom: 16px;
  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
